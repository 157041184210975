import React, {Component} from 'react';
import "./Footer.css";

export class Footer extends Component {

    render() {
        return (
            <div>
                <div className="footer-copyright text-center py-3">
                    <div className="container-fluid">
                        &copy; {new Date().getFullYear()}
                        &nbsp;CamDiab Ltd
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                            href="http://camdiab.com/terms"
                            title=" Terms and conditions" target="_blank"
                            rel="noopener noreferrer">T&C</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                            href="http://camdiab.com/privacy"
                            title="Privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
                    </div>
                </div>
            </div>
        );
    }
}

