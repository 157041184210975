import React from "react";
import {Route, Switch} from "react-router-dom";
import Day from "./containers/Day";
import Days from "./containers/Days";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import Users from "./containers/Users";
import ChangePassword from "./containers/ChangePassword";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import NewUser from "./containers/NewUser";
import User from "./containers/User";
import MyInfo from "./containers/MyInfo";
import Week from "./containers/Week";
import Weeks from "./containers/Weeks";
import Month from "./containers/Month";
import Months from "./containers/Months";
import Orders from "./containers/Orders";
import Order from "./containers/Order";
import NewOrder from "./containers/NewOrder";

// eslint-disable-next-line
export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/" exact component={Days} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
        <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps}/>
        <AuthenticatedRoute path="/:cl/orders" exact component={Orders} props={childProps}/>
        <AuthenticatedRoute path="/:cl/orders/new" exact component={NewOrder} props={childProps}/>
        <AuthenticatedRoute path="/:cl/orders/:id" exact component={Order} props={childProps}/>
        <AuthenticatedRoute path="/day" exact component={Days} props={childProps}/>
        <AuthenticatedRoute path="/:cl/day/:id" exact component={Day} props={childProps}/>
        <AuthenticatedRoute path="/:cl/day" exact component={Days} props={childProps}/>
        <AuthenticatedRoute path="/:cl/week/:id" exact component={Week} props={childProps}/>
        <AuthenticatedRoute path="/:cl/week" exact component={Weeks} props={childProps}/>
        <AuthenticatedRoute path="/:cl/month/:id" exact component={Month} props={childProps}/>
        <AuthenticatedRoute path="/:cl/month" exact component={Months} props={childProps}/>
        <AuthenticatedRoute path="/:cl/users/new" exact component={NewUser} props={childProps}/>
        <AuthenticatedRoute path="/:cl/users/:id" exact component={User} props={childProps}/>
        <AuthenticatedRoute path="/:cl/info/:id" exact component={MyInfo} props={childProps}/>
        <AuthenticatedRoute path="/:cl/users" exact component={Users} props={childProps}/>
        <AuthenticatedRoute path="/:cl/settings" exact component={Settings} props={childProps}/>
        <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps}/>
        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound}/>
    </Switch>;
