import React, {Component} from "react";
import {ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import {signIn, signOut} from "aws-amplify/auth";
import {Link} from "react-router-dom";
import {getClinicDetails, getUserCanOrder} from "../libs/user-lib";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: "",
            clinicId: this.props.cl,
            clinicIdFetched: false
        };
    }

    validateForm() {
        return (this.state.email.trim().length > 0) &&
            (this.state.password.trim().length > 0) &&
            (this.state.clinicId.trim().length > 0);
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleChangeProps = event => {
        this.props.setCl(event.target.value);
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        let error;
        try {
            // reload data
            this.props.setRowDataUsers([]);
            this.props.setRowDataOrders([]);
            // reload data
            this.props.setRowData([]);
            this.props.setRowDataWeek([]);
            this.props.setRowDataMonth([]);
            this.props.setSearchBox("");

            let user = await signIn({username: this.state.email.trim(), password: this.state.password.trim()});
            if (!user.isSignedIn) {
                user = await signIn({
                    username: this.state.email.trim().toLowerCase(),
                    password: this.state.password.trim()
                });
            }

            if (!user.isSignedIn) {
                throw new Error("Could not log in");
            }
            if (!user.attributes) {
                user.attributes = {}
            }
            user.attributes.email = this.state.email.trim().toLowerCase();
            this.props.setCurrentUser(user);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.props.userNeedsNewPassword(true);
                this.props.history.push('/settings/password');
            } else {
                await getClinicDetails(this, this.props, this.props, user, this.state.clinicId);
                await getUserCanOrder(this.props, this.props, user.attributes.email);
            }
        } catch (e) {
            let err = e;
            if (error) {
                err = error;
            }
            await signOut();
            if (err.response && err.response.data && err.response.data.messageUser) {
                alert(err.response.data.messageUser);
            } else {
                alert(err.message);
            }
            this.setState({isLoading: false});
        }
    };

    render() {
        if ((this.state.clinicId.trim() === "")
            && (this.props.cl.trim() !== "")
            && (!this.state.clientIdFetched)
            && (this.props.cl.trim() !== "day")
            && (this.props.cl.trim() !== "undefined")
            && (this.props.cl.trim() !== "settings")) {
            this.setState({clinicId: this.props.cl.trim()});
            this.setState({clientIdFetched: true});
        }

        this.state.clinicId.trim() === "" ? this.props.cl.trim() : this.state.clinicId.trim()
        return (
            <div className="Login">
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="email" bsSize="large">
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            autoFocus
                            type="email"
                            value={this.state.email.trim()}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                        />
                    </FormGroup>
                    <FormGroup controlId="clinicId" bsSize="large">
                        <ControlLabel>Clinic ID</ControlLabel>
                        <FormControl
                            type="text"
                            value={this.state.clinicId.trim()}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <Link to="/login/reset">Forgot password?</Link>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Login"
                        loadingText="Logging in…"
                    />
                </form>
            </div>
        );
    }
}
