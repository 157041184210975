import React, {Component} from "react";
import {getWrapper as get, putWrapper as put, delWrapper as del, handleApiErrorWithAlert} from "../libs/awsApiWrappers";
import LoaderButton from "../components/LoaderButton";
import "./User.css";
import {AgGridReact} from "ag-grid-react";
import {columnDefsUsers, setColDefUserForDataEntry} from "../libs/columnDefUsers";
import {validateFormUser} from "../libs/user-lib";
import * as globalsUser from "../libs/globalDefUsers";

export default class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            user: null,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsUsers)),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '105px'
            },

            // components: {datePicker: this.getDatePicker()},
            rowDataUsers: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        setColDefUserForDataEntry(true, this, false);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowDataUsers: data});
        };

        updateData(this.state.rowDataUsers);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    async componentDidMount() {
        try {
            const user = await this.getUser();
            if (user[globalsUser.userEmail] === this.props.currentUser.attributes.email) {
                user[globalsUser.ordering] = true;
            }
            this.setState({rowDataUsers: [user]});
        } catch (e) {
           handleApiErrorWithAlert(e);
        }
        this.props.setUpdateTimeOutListener();
    }

    getUser() {
        let myInit = {
            queryStringParameters: {
                clinicId: this.props.cl,
            }
        };
        return get("clinic", `/users/${this.props.match.params.id}`, myInit);
    }

    saveUser(user, reset) {
        const object = {
            clinicId: this.props.cl,
            email: this.props.match.params.id,
            name: user[globalsUser.userName],
            enabled: user[globalsUser.enabled],
            daily: user[globalsUser.daily],
            weekly: user[globalsUser.weekly],
            monthly: user[globalsUser.monthly],
            ordering: user[globalsUser.userEmail] === this.props.currentUser.attributes.email ?
                true : user[globalsUser.ordering],
            clinicName: this.props.currentClinic.clinicName,
            clinicNameShort: this.props.currentClinic.clinicNameShort,
            locale: "EN",
        };
        return put("clinic", `/users/${this.props.match.params.id}`, {
            body: object
        });
    }

    deleteUser() {
        let myInit = {
            queryStringParameters: {
                clinicId: this.props.cl,
                email: this.props.match.params.id
            }
        };
        return del("clinic", `/users/${this.props.match.params.id}`, myInit);
    }

    handleSubmit = async event => {

        event.preventDefault();
        this.gridApi.stopEditing();
        if (validateFormUser(this)) {
            this.setState({isLoading: true});

            try {
                const element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.saveUser(element, false);
                // reload data
                this.props.setRowDataUsers([]);
                this.props.history.push("/" + this.props.cl + "/users");
            } catch (e) {
               handleApiErrorWithAlert(e);
                this.setState({isLoading: false});
            }
        }
    };

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this user? This cannot be undone."
        );

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            await this.deleteUser();
            // reload data
            this.props.setRowDataUsers([]);
            this.props.history.push("/" + this.props.cl + "/users");
        } catch (e) {
           handleApiErrorWithAlert(e);
            this.setState({isDeleting: false});
        }
    };

    render() {
        return (
            <div className="User">
                {this.state.rowDataUsers &&
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            // components={this.state.components}
                            rowData={this.state.rowDataUsers}
                            // headerHeight={58}
                        />
                    </div>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                </form>}
            </div>
        );
    }
}
