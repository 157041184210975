import * as globalsUser from "./globalDefUsers";

/**
 * Shared ag-grid column definition for users
 * @type {*[]}
 */
export const columnDefsUsers = [{
    headerName: "User email", field: globalsUser.userEmail,
    pinned: 'left',
    width: 260,
}, {
    headerName: "User name", field: globalsUser.userName,
    width: 250
}, {
    headerName: "Daily notifications", field: globalsUser.daily,
    width: 160,
    cellStyle: {textAlign: 'center'},
    cellRenderer: checkBoxRenderer
}, {
    headerName: "Weekly notifications", field: globalsUser.weekly,
    width: 160,
    cellStyle: {textAlign: 'center'},
    cellRenderer: checkBoxRenderer
}, {
    headerName: "Monthly notifications", field: globalsUser.monthly,
    width: 160,
    cellStyle: {textAlign: 'center'},
    cellRenderer: checkBoxRenderer
}, {
    headerName: "Dana ordering enabled", field: globalsUser.ordering,
    width: 200,
    cellStyle: {textAlign: 'center'},
    cellRenderer: checkBoxRenderer
}, {
    headerName: "User enabled", field: globalsUser.enabled,
    width: 160,
    cellStyle: {textAlign: 'center'},
    cellRenderer: checkBoxRenderer
},
];

// render a checkbox (not editable)
function checkBoxRenderer(params) {
    return (
        <input
            type="checkbox"
            checked={params.value && params.value !== "false"}
            disabled={true}
            onChange={(event) => {
                const updatedValue = event.target.checked;
                params.node.setDataValue(params.column.colId, updatedValue);
                params.node.data.enabled = updatedValue;
            }}
        />
    );
}

// render a checkbox (editable)
function checkBoxRendererEditable(params) {
    return (
        <input
            type="checkbox"
            checked={params.value && params.value !== "false"}
            disabled={false}
            onChange={(event) => {
                const updatedValue = event.target.checked;
                params.node.setDataValue(params.column.colId, updatedValue);
                params.node.data.enabled = updatedValue;
            }}
        />
    );
}

// set the field cell renderer to be an editable checkbox
function doCheckbox(field) {
    field.cellRenderer = checkBoxRendererEditable;
}

/**
 * Update column definition for data entry
 */
export function setColDefUserForDataEntry(editing, self, infoSection) {
    let field;
    let i;

    for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
        field = self.state.columnDefs[i];

        if (field.field === globalsUser.enabled) {
            if (infoSection) {
                self.state.columnDefs.splice(i);
            } else {
                doCheckbox(field);
            }
        } else if (field.field === globalsUser.ordering) {
            if (infoSection) {
                self.state.columnDefs.splice(i);
            } else {
                doCheckbox(field);
            }
        } else if (field.field === globalsUser.daily) {
            doCheckbox(field);
        } else if (field.field === globalsUser.weekly) {
            doCheckbox(field);
        } else if (field.field === globalsUser.monthly) {
            doCheckbox(field);
        }
        if (editing) {
            if ((field.field !== globalsUser.userEmail) &&
                (field.field !== globalsUser.enabled) &&
                (field.field !== globalsUser.ordering) &&
                (field.field !== globalsUser.daily) &&
                (field.field !== globalsUser.weekly) &&
                (field.field !== globalsUser.monthly)) {
                field.editable = true;
            }
        } else {
            if ((field.field !== globalsUser.enabled) &&
                (field.field !== globalsUser.daily) &&
                (field.field !== globalsUser.weekly) &&
                (field.field !== globalsUser.monthly) &&
                (field.field !== globalsUser.ordering)) {
                field.editable = true;
            }
        }
    }
}
