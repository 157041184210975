import {get, put, del, post} from "aws-amplify/api";

/**
 * Wrapper for the get function call
 * @param api
 * @param path
 * @param params
 * @returns {Promise<*>}
 */
export async function getWrapper(api, path, params) {
    params.queryParams = params.queryStringParameters
    const { body } = await get( { apiName: api, path: path, options: params } ).response;
    return body.json();
}

/**
 * Wrapper for the put function call
 * @param api
 * @param path
 * @param params
 * @returns {Promise<*>}
 */
export async function putWrapper(api, path, params) {
    params.queryParams = params.queryStringParameters
    const { body } = await put( { apiName: api, path: path, options: params } ).response;
    return body.json();
}

/**
 * Wrapper for the del function call
 * @param api
 * @param path
 * @param params
 * @returns {Promise<*>}
 */
export async function delWrapper(api, path, params) {
    params.queryParams = params.queryStringParameters
    const { body } = await del( { apiName: api, path: path, options: params } ).response;
    return body.json();
}

/**
 * Wrapper for the post function call
 * @param api
 * @param path
 * @param params
 * @returns {Promise<*>}
 */
export async function postWrapper(api, path, params) {
    params.queryParams = params.queryStringParameters
    const { body } = await post( { apiName: api, path: path, options: params } ).response;
    return body.json();
    //return body;
}

/**
 * Extract and show a response error message
 * @param e
 * @returns whether we should log the user out (fatal error)
 */
export function handleApiErrorWithAlert(e) {
    console.log("API error: " + JSON.stringify(e.response));

    let alertMessage = e;
    if (e.response && e.response.body) {
        try {
            const errorData = JSON.parse(e.response.body);
            if (errorData.messageUser) {
                alertMessage = errorData.messageUser;
            }
        } catch (parseError) {
            console.error("Failed to parse response body:", parseError);
        }
    }

    alert(alertMessage);
    return JSON.stringify(alertMessage).includes("Missing Authentication Token");
}