import {userEmail, userName, ordering} from "./globalDefUsers";
import {getWrapper as get} from "./awsApiWrappers";
import {signOut} from "aws-amplify/auth";
import {pumpSN} from "./globalDef";
import {pumpMake, startDate, supplier, userInitials} from "./globalDefOrdering";

/**
 * Default pump serial number
 * @type {string}
 */
export const defaultSN = 'AAA00000AA';

/**
 * Default user ID
 * @type {string}
 */
export const defaultUser = 'XXXX@gmail.com';


/**
 * Check whether date is within today and the next 6 weeks
 * @param value Value to check
 * @param Whether this is editing
 * @returns {boolean} Whether date is within today and the next 6 weeks
 */
function dateWithinSixWeeks(value, editing) {
    let result = false;
    const now = new Date().toISOString().slice(0, 10);
    let sixWeeksLater = new Date();
    sixWeeksLater.setDate(sixWeeksLater.getDate() + (6 * 7));
    sixWeeksLater = sixWeeksLater.toISOString().slice(0, 10);
    if (((value >= now) || editing) && (value <= sixWeeksLater)) {
        result = true;
    } else {
        alert('Incorrect start date. It should be between today and six weeks ahead.');
    }
    return result;
}

/**
 * Validate date
 * @param value Value to check
 * @returns {boolean} Whether date is valid
 */
export function validateDate(value) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    // Match the date format through regular expression
    const invalidDate = 'Invalid date format - use YYYY-MM-DD';
    if (!value || (typeof value !== 'string')) {
        alert(invalidDate);
        return false;
    }

    if (!value.match(regEx)) {
        alert(invalidDate);
        return false;
    }
    const d = new Date(value);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) {
        // NaN value, Invalid date
        alert(invalidDate);
        return false;
    }
    if (d.toISOString().slice(0, 10) === value) {
        return true;
    } else {
        alert(invalidDate);
        return false;
    }
}

/**
 * Validate email address
 * @param email Email address to validate
 * @returns {boolean} Whether email is valid
 */
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function isCharacterALetter(char) {
    return (/[a-zA-Z]/).test(char);
}

function isCharacterANumber(char) {
    return (/[0-9]/).test(char);
}

/**
 * Validate pump serial number
 * @param pumpSNtoCheck Pump SN to validate
 * @returns {boolean} Whether email is valid
 */
function validatePumpSN(pumpSNtoCheck) {
    let result = false;
    pumpSNtoCheck = pumpSNtoCheck.toUpperCase().trim();
    if (pumpSNtoCheck &&
        (pumpSNtoCheck.length === 10) &&
        ((pumpSNtoCheck[9] === 'I') || (pumpSNtoCheck[9] === 'B'))) {
        result =
            isCharacterALetter(pumpSNtoCheck[0]) &&
            isCharacterALetter(pumpSNtoCheck[1]) &&
            isCharacterALetter(pumpSNtoCheck[2]) &&
            isCharacterALetter(pumpSNtoCheck[8]) &&
            isCharacterALetter(pumpSNtoCheck[9]) &&
            isCharacterANumber(pumpSNtoCheck[3]) &&
            isCharacterANumber(pumpSNtoCheck[4]) &&
            isCharacterANumber(pumpSNtoCheck[5]) &&
            isCharacterANumber(pumpSNtoCheck[6]) &&
            isCharacterANumber(pumpSNtoCheck[7]);
    }
    return result;
}

export function validateFormUser(self) {
    let result = true;
    if (self.gridApi) {
        const el = self.gridApi.getDisplayedRowAtIndex(0).data;
        if ((!el[userEmail]) || (el[userEmail] === defaultUser) || (!validateEmail(el[userEmail]))) {
            result = false;
            alert("Invalid user email")
        } else if (!el[userName] || (el[userName].length < 3)) {
            alert("Incorrect name");
            result = false;
        }
    }
    return result; // self.state.content.length > 0;
}

/**
 * Validate data when submitting a new or updated order
 * @param self This object
 * @param editing Whether record is being edited
 * @returns {boolean} Whether data is valid
 */
export function validateFormOrder(self, editing) {
    let result = true;
    if (self.gridApi) {
        const el = self.gridApi.getDisplayedRowAtIndex(0).data;
        if (!validatePumpSN(el[pumpSN])) {
            result = false;
            alert("Invalid pump SN")
        }
        if (!el[pumpMake]) {
            result = false;
            alert("Missing pump make")
        }
        if (!el[supplier]) {
            result = false;
            alert("Missing supplier")
        }
        if (!el[userInitials]) {
            result = false;
            alert("Missing user initials")
        }
        if ((!validateDate(el[startDate])) || (!dateWithinSixWeeks(el[startDate], editing))) {
            result = false;
        }
    }
    return result; // self.state.content.length > 0;
}

export async function signOutFunction(self, props) {
    await signOut();
    // reload data
    props.setRowData([]);
    props.setRowDataWeek([]);
    props.setRowDataMonth([]);
    props.setRowDataUsers([]);
    props.setRowDataOrders([]);
    props.setSearchBox("");
    props.setCurrentClinic(null);
    props.setCl("");
    props.setCurrentUserClinicTable(null);
    props.userHasAuthenticated(false);
    props.props.history.push("/login");

    // self.props.history.push("/login");
}

export async function getUserCanOrder(self, props, email) {
    const user = await getUser(props, email);
    if ((user[ordering] === true) ||
        ((user[ordering] === undefined) &&
            (props.currentClinic.adminID.toLowerCase() ===
                email.toLowerCase()))) {
        self.setIsOrderer(true);
    } else {
        self.setIsOrderer(false);
    }
}

async function getUser(props, email) {
    let myInit = {
        queryStringParameters: {
            clinicId: props.cl,
        }
    };
    return get("clinic", `/users/${email}`, myInit);
}

export async function getClinicDetails(self, state, func, user, clinicId) {
    const clinic = await get("clinic", `/clinic/${clinicId}`, {});
    func.setCurrentClinic(clinic);
    if (!clinic) {
        await signOut();
        alert("Incorrect Clinic ID");
        self.setState({isLoading: false});
    } else {
        // check if user enabled
        let myInit = {
            queryStringParameters: {
                clinicId: clinicId,
            }
        };
        const userFromTable = await get("clinic", `/users/${user.attributes.email}`, myInit);
        func.setCurrentUserClinicTable(userFromTable);
        if (!userFromTable || (!userFromTable.enabled) || (userFromTable.enabled === "false")) {
            await signOut();
            alert("Your account is disabled. Please contact your CamAPS FX Clinic Administrator " + clinic.adminName +
                " (" + clinic.adminID + ") to restore access");
            self.setState({isLoading: false});
        } else {
            func.userNeedsNewPassword(false);
            func.userHasAuthenticated(true);
            func.setIsEditor(clinic.adminID.toLowerCase() ===
                user.attributes.email.toLowerCase());
        }
    }
}