/**
 * Subject user name (email address)
 * @type {string}
 */
export const userName = "userName";

/**
 * Subject user name (email address)
 * @type {string}
 */
export const userNameNew = "userNameNew";

/**
 * Subject user name (email address)
 * @type {string}
 */
export const name = "name";

/**
 * Subject user name (email address)
 * @type {string}
 */
export const nameNew = "nameNew";

/**
 * Whether subject user name should be small size
 * @type {string}
 */
export const nameNewSmall = "nameNewSmall";

/**
 * Pump serial number
 * @type {string}
 */
export const pumpSN = "pumpSN";

/**
 * End date
 * @type {string}
 */
export const endDate = "endDate";

/**
 * CGM availability
 * @type {string}
 */
export const availabilityCgm = "availabilityCgm";

/**
 * CL availability
 * @type {string}
 */
export const availabilityCl = "availabilityCl";

/**
 * CGM mean glucose value
 * @type {string}
 */
export const cgmMean = "cgmMean";

/**
 * GMI percent value
 * @type {string}
 */
export const gmiPercent = "gmiPercent";

/**
 * CGM variability SD
 * @type {string}
 */
export const cgmVarSD = "cgmVarSD";

/**
 * CGM variability CV
 * @type {string}
 */
export const cgmVarCV = "cgmVarCV";

/**
 * Time in range
 * @type {string}
 */
export const cgmTargetIn = "cgmTargetIn";

/**
 * Time below range
 * @type {string}
 */
export const cgmTargetBelow = "cgmTargetBelow";

/**
 * Time above range
 * @type {string}
 */
export const cgmTargetAbove = "cgmTargetAbove";

/**
 * Number of hypos
 * @type {string}
 */
export const hyposNumber = "hyposNumber";

/**
 * Duration of hypos
 * @type {string}
 */
export const hyposDuration = "hyposDuration";

/**
 * Total insulin delivery
 * @type {string}
 */
export const insulin = "insulin";

/**
 * Insulin bolus
 * @type {string}
 */
export const insulinBolus = "insulinBolus";

/**
 * Insulin basal
 * @type {string}
 */
export const insulinBasal = "insulinBasal";

/**
 * Number of refills
 * @type {string}
 */
export const refillNumber = "refillNumber";

/**
 * Number of primes
 * @type {string}
 */
export const primeNumber = "primeNumber";

/**
 * Number of sensor inserts
 * @type {string}
 */
export const sensorInsert = "sensorInsert";

/**
 * Number of CL exits
 * @type {string}
 */
export const noClAll = "noClAll";

/**
 * Exit due to no cgm
 * @type {string}
 */
export const noClCgm = "noClCgm";

/**
 * Exit due to no pump
 * @type {string}
 */
export const noClPump = "noClPump";

/**
 * Exit due to pump suspend
 * @type {string}
 */
export const noClSuspend = "noClSuspend";

/**
 * Exit due to TDD limit
 * @type {string}
 */
export const noClTddLimit = "noClTddLimit";

/**
 * Exit due to other reason
 * @type {string}
 */
export const noClOther = "noClOther";

/**
 * Exit due to no BT
 * @type {string}
 */
export const noClBt = "noClBt";

/**
 * Sound alerts
 * @type {string}
 */
export const alertSound = "alertSound";

/**
 * Alert sound night
 * @type {string}
 */
export const alertSoundNight = "alertSoundNight";

/**
 * Alert vibrate
 * @type {string}
 */
export const alertVibrate = "alertVibrate";

/**
 * Alert vibrate night
 * @type {string}
 */
export const alertVibrateNight = "alertVibrateNight";

/**
 * Low threshold
 * @type {string}
 */
export const thresholdLow = "thresholdLow";

/**
 * Threshold high
 * @type {string}
 */
export const thresholdHigh = "thresholdHigh";


/**
 * Display threshold
 * @type {string}
 */
export const displayThreshold = "displayThreshold";

/**
 * Combined name and email
 * @type {string}
 */
export const combined = "combined";

/**
 * Standard column width
 * @type {number}
 */
export const standardColWidth = 100;

/**
 * Narrow column width
 * @type {number}
 */
export const narrowColWidth = 92;
