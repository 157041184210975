import React, {Component} from "react";
import {getWrapper as get, handleApiErrorWithAlert} from "../libs/awsApiWrappers";
import {PageHeader, Button, Glyphicon} from "react-bootstrap";
import "./Months.css";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import * as columnDefinition from "../libs/columnDef";
import {signOutFunction} from "../libs/user-lib";
import {comVal} from "../libs/columnDef";
import * as globals from "../libs/globalDef";
import {combined, userName, name} from "../libs/globalDef";
import {gmiPercent} from "../libs/globalDef";
import {cgmTargetBelow} from "../libs/globalDef";
import {insulin} from "../libs/globalDef";
import {insulinBolus} from "../libs/globalDef";
import {insulinBasal} from "../libs/globalDef";

const daysPerStatRecord = 30;

export default class Months extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            selectedRowData: [],

            type: "Month",
            daysPerRecord: daysPerStatRecord,

            columnDefs: columnDefinition.columnDefs,

            defaultColDef: {
                sortable: true,
                filter: true,
                lockPinned: true,
                resizable: true,
                comparator: comVal,
            },
            rowStyleSelector: function (params) {
                if (params.node.rowPinned) {
                    return {color: "blue"};
                }
                return undefined;
            },
            style: {
                width: '100%',
                height: '644px'
                // height: '364px'
                // height: '2884px'
            },
            rowSelection: "single",
            paginationPageSize: 100,
            paginationNumberFormatter: function (params) {
                return params.value.toLocaleString();
            },
            pinnedTopRowData: [{[globals.userNameNew]: "Mean"}],
        };

        const self = this;
        // updated to return a React element, not a DOM node.
        this.state.columnDefs[0].cellRenderer = function (params) {
            if (params.value) {
                if (params.data[globals.pumpSN] !== undefined) {

                    return (
                        <a
                            href="#month"
                            onClick={(e) => {
                                e.preventDefault();
                                self.props.setUserToDuplicate(null);
                                self.props.history.push(
                                    '/' + self.props.cl +
                                    '/month/' + params.data[globals.pumpSN]
                                );
                            }}
                        >
                            {params.value}
                        </a>
                    );
                } else {
                    return (params.value);
                }
            }
            return null;
        };

    }

    getRowNodeId = params => {
        return params.id;
    };

    onModelUpdated = params => {
        const size = params.api.getDisplayedRowCount();
        const mean = {};
        const n = {};
        for (let i = 0; i < size; i++) {
            const node = params.api.getDisplayedRowAtIndex(i);
            if (i > 0) {
                const nodePrevious = params.api.getDisplayedRowAtIndex(i - 1);
                if (node.data[globals.userName] === nodePrevious.data[globals.userName]) {
                    node.setDataValue(globals.userNameNew, '');
                    node.setDataValue(globals.nameNew, '');
                } else {
                    node.setDataValue(globals.userNameNew, node.data[globals.userName]);
                    node.setDataValue(globals.nameNew, node.data[globals.name]);
                }
            } else {
                node.setDataValue(globals.userNameNew, node.data[globals.userName]);
                node.setDataValue(globals.nameNew, node.data[globals.name]);
            }
        }
        this.calculateMean(this, params, size, mean, n);
    };

    async calculateMean(self, params, size, mean, n) {
        const conver = 0.05551;
        let isMmol = false;
        for (let i = 0; i < size; i++) {
            const node = params.api.getDisplayedRowAtIndex(i);
            for (let attr in node.data) {
                if ((attr !== globals.userNameNew) &&
                    (attr !== globals.nameNew) &&
                    (attr !== globals.pumpSN) &&
                    (attr !== globals.endDate) &&
                    (attr !== globals.displayThreshold) &&
                    (attr !== globals.combined)) {
                    try {
                        const nd = node.data[attr];
                        let value = parseFloat(nd.replace(/,/g, '.'));
                        if (!isNaN(value)) {
                            if (nd.includes("mg")) {
                                value *= conver;
                            } else if (nd.includes("mmol/L")) {
                                isMmol = true;
                            }
                            mean[attr] = !mean[attr] ? value : mean[attr] + value;
                            n[attr] = !n[attr] ? 1 : n[attr] + 1;
                        }
                    } catch (e) {
                    }
                }
            }
        }
        for (let attr in mean) {
            try {
                mean[attr] = mean[attr] / n[attr];
                switch (attr) {
                    case globals.availabilityCgm:
                    case globals.availabilityCl:
                    case globals.gmiPercent:
                    case globals.cgmVarCV:
                    case globals.cgmTargetIn:
                    case globals.cgmTargetBelow:
                    case globals.cgmTargetAbove:
                        if (mean[attr] > 10.0) {
                            mean[attr] = mean[attr].toFixed(0);
                        } else {
                            mean[attr] = mean[attr].toFixed(1);
                        }
                        mean[attr] += " %";
                        break;
                    case globals.cgmMean:
                    case globals.cgmVarSD:
                        if (isMmol) {
                            mean[attr] = mean[attr].toFixed(1);
                            mean[attr] += " mmol/L"
                        } else {
                            mean[attr] = mean[attr] / conver;
                            mean[attr] = mean[attr].toFixed(0);
                            mean[attr] += " mg/dL"
                        }
                        break;
                    case globals.insulin:
                    case globals.insulinBolus:
                    case globals.insulinBasal:
                        if (mean[attr] > 10.0) {
                            mean[attr] = mean[attr].toFixed(0);
                        } else {
                            mean[attr] = mean[attr].toFixed(1);
                        }
                        mean[attr] += " U/day";
                        break;
                    case globals.hyposDuration:
                        if (mean[attr] > 10.0) {
                            mean[attr] = mean[attr].toFixed(0);
                        } else {
                            mean[attr] = mean[attr].toFixed(1);
                        }
                        mean[attr] += " minutes";
                        break;
                    default:
                        if (mean[attr] > 10.0) {
                            mean[attr] = mean[attr].toFixed(0);
                        } else {
                            mean[attr] = mean[attr].toFixed(1);
                        }
                        break;
                }
            } catch (e) {
            }
        }
        mean[globals.userNameNew] = "Mean";
        self.setState({pinnedTopRowData: [mean]});
    }

    onGridReady = params => {
        this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.props.setRowDataMonth(data);
            params.api.paginationGoToPage(0);
        };

        updateData(this.props.rowDataMonth);
        const value = this.props.searchBoxValue;
        document.getElementById('filter-text-box').value = value;
        params.api.setGridOption("quickFilterText", value);

        const valuePageSize = this.props.pageSize;
        document.getElementById("page-size").value = valuePageSize;
        this.gridApi.setGridOption("paginationPageSize", Number(valuePageSize));

        document.getElementById("data-size").value = this.props.dataSizeMonth;
    };

    async onNumberOfDaysChanged() {
        // const pxPerRow = 28;
        const value = document.getElementById("data-size").value;
        this.props.setDataSizeMonth(value);
        this.props.setRowDataMonth([]);
        this.setState({isLoading: true});
        this.setState({pinnedTopRowData: [{[globals.userNameNew]: "Mean"}]});
        await this.getDataFromAws(true, value);
    }

    onPageSizeChanged() {
        // const pxPerRow = 28;
        const value = document.getElementById("page-size").value;
        // this.props.setPageSize(value);
        this.gridApi.setGridOption("paginationPageSize", Number(value));

        // const newHeight = ((Number(value) + 3) * pxPerRow).toString() + "px";
        // this.setHeight(newHeight);
    }

    onSelectionChanged() {
        this.setState({selectedRowData: this.gridApi.getSelectedRows()});
    }

    async componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        if (this.props.match.path === "/") {
            if (this.props.currentClinic) {
                this.props.history.push("/" + this.props.currentClinic.clinicID + "/day");
                return;
            } else {
                this.props.history.push("/day");
                return;
            }
        }

        const pathnameSeg = this.props.location.pathname.split('/');
        if (pathnameSeg && (pathnameSeg.length > 1) && (pathnameSeg[1].length > 0)) {
            if (this.props.cl !== pathnameSeg[1]) {
                let clinic;
                try {
                    clinic = await get("clinic", `/clinic/${pathnameSeg[1]}`, {});
                } catch (e) {
                    console.log(e.toString());
                }
                if (!clinic) {
                    if (pathnameSeg[1] === "day") {
                        await signOutFunction(this, this.props);
                    } else {
                        this.props.history.push(this.props.location.pathname + "/unknown");
                    }
                    return;
                } else {
                    let myInit = {
                        queryStringParameters: {
                            clinicId: pathnameSeg[1]
                        }
                    };
                    const userFromTable = get("clinic", `/users/${this.props.currentUser.attributes.email}`, myInit);
                    if (!userFromTable || (!userFromTable.enabled) || (userFromTable.enabled === "false")) {
                        if (pathnameSeg[1] === "day") {
                            alert("User is disabled. Please contact your CamAPS FX Administrator " + clinic.adminName +
                                "(" + clinic.adminID + ") to restore access");
                            await signOutFunction(this, this.props);
                        } else {
                            this.props.history.push(this.props.location.pathname + "/unknown");
                        }
                    } else {
                        this.props.setCurrentClinic(clinic);
                        this.props.setIsEditor(clinic.adminID.toLowerCase() ===
                            this.props.currentUser.attributes.email.toLowerCase());                    }
                }
            }
        }
        await this.getDataFromAws(false, this.props.dataSizeMonth);
    }

    async getDataFromAws(force, dataSize) {
        if ((this.props.rowDataMonth.length === 0) || (force)) {
            const ch = String.fromCharCode(160);
            const str = ch + ch + ch + ch;
            try {
                const statLines = await this.getStatLInes(dataSize);
                let isName;
                for (let line of statLines.Items) {
                    line.daysPerRecord = this.state.daysPerRecord;
                    line[combined] = str + line[userName] + " " + line[name];
                    if (line[name] && (line[name].length > 0)) {
                        isName = true;
                    }
                    line[gmiPercent] = line[gmiPercent].replace(/,/g, '.');
                    line[cgmTargetBelow] = line[cgmTargetBelow].replace(/,/g, '.');
                    line[insulin] = line[insulin].replace(/,/g, '.');
                    line[insulinBolus] = line[insulinBolus].replace(/,/g, '.');
                    line[insulinBasal] = line[insulinBasal].replace(/,/g, '.');
                }
                // use setState to update reference to existing state after modification
                this.setState((prevState) => {
                    prevState.columnDefs[1].hide = !isName;
                    return { columnDefs: prevState.columnDefs };
                });
                this.props.setRowDataMonth(statLines.Items);
            } catch (e) {
                if (handleApiErrorWithAlert(e)) {
                    console.log("Logging out due to fatal error");
                    this.props.logoutNow();
                }
            }
        }

        this.props.setUpdateTimeOutListener();
        this.setState({isLoading: false});
    }

    getStatLInes(dataSize) {
        let myInit = {
            queryStringParameters: {
                clinicId: this.props.cl,
                queryType: this.state.type.toLowerCase(),
                noDays: daysPerStatRecord * dataSize
            }
        };
        return get("clinic", "/day", myInit);
    }

    onFilterTextBoxChanged() {
        if (this.gridApi) {
            const value = document.getElementById('filter-text-box').value;
            this.props.setSearchBox(value);
            this.gridApi.setGridOption("quickFilterText", value);
        }
    }

    onBtExport() {
        const params = {
            skipHeader: false,
            skipFooters: false,
            skipGroups: false,
            skipPinnedTop: false,
            skipPinnedBottom: false,
            allColumns: true,
            onlySelected: false,
            suppressQuotes: true,
            fileName: "CamAPS FX Performance Month",
            columnSeparator: ',',
            customHeader: "CamAPS FX Performance Month\n"
        };
        this.gridApi.exportDataAsCsv(params);
    }

    renderPumpsList() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <div className="pump-container">
                    <div className="page-container">
                        <Button className="pull-right" bsStyle="info" bsSize="small"
                                onClick={this.onBtExport.bind(this)}>
                            <Glyphicon glyph="file"/> Export to Excel
                        </Button>
                    </div>
                    <div className="page-container">
                        <div className="page-size-header pull-left">
                            Past months:&nbsp;
                            <select onChange={this.onNumberOfDaysChanged.bind(this)} id="data-size"
                                    defaultValue={this.props.dataSizeMonth}>
                                {/*<select id="page-size" defaultValue={'100'}>*/}
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                        <div className="page-size-header pull-left invisible">
                            Page size:&nbsp;
                            <select onChange={this.onPageSizeChanged.bind(this)} id="page-size" defaultValue={'1000'}>
                                {/*<select id="page-size" defaultValue={'100'}>*/}
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                        <input className="pull-right" type="text" id="filter-text-box" placeholder="Search..."
                               onInput={this.onFilterTextBoxChanged.bind(this)}/>
                    </div>
                    <div className="grid-wrapper">
                        <div
                            id="pumpGrid"
                            style={this.state.style}
                            className="ag-theme-balham"
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowSelection={this.state.rowSelection}
                                getRowStyle={this.state.rowStyleSelector}
                                onGridReady={this.onGridReady}
                                getRowNode={this.getRowNodeId}
                                onModelUpdated={this.onModelUpdated}
                                enableCellTextSelection={true}
                                pinnedTopRowData={this.state.pinnedTopRowData}
                                frameworkComponents={this.state.frameworkComponents}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                pagination={true}
                                paginationPageSize={this.state.paginationPageSize}
                                paginationNumberFormatter={this.state.paginationNumberFormatter}
                                rowData={this.props.rowDataMonth}
                                headerHeight={58}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>CamAPS FX Clinic</h1>
                {/*<p>Managing access to pumps for CamAPS app and other administrative tasks</p>*/}
            </div>
        );
    }

    getHeader() {
        if (this.state.isLoading) {
            return ('Loading ...');
        } else {
            return (
                this.props.currentClinic ? this.props.currentClinic.clinicNameShort.trim() + ": Month statistics" : "")
        }
    }

    renderData() {
        return (
            <div className="pumps">

                {/*<div className="left-header pull-left">*/}
                <PageHeader>
                    <div className="header">
                        <div className="left-header pull-left">
                            {this.getHeader()}
                        </div>
                        <div className="pull-right">
                            {/*<h4 className="text-right">*/}
                            {/*    {this.getUser()}*/}
                            {/*</h4>*/}
                        </div>
                        <div className="clearfix"/>
                    </div>
                </PageHeader>
                {!this.state.isLoading && this.renderPumpsList()}
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderData() : this.renderLander()}
            </div>
        );
    }
}
