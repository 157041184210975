import React, {Component} from "react";
import {getWrapper as get, handleApiErrorWithAlert} from "../libs/awsApiWrappers";
import {Button, Glyphicon, PageHeader} from "react-bootstrap";
import "./Users.css";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import {columnDefsUsers} from "../libs/columnDefUsers";
import * as globalsUser from "../libs/globalDefUsers";

export default class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            users: [],
            selectedUsers: [],

            columnDefs: columnDefsUsers,

            defaultColDef: {
                sortable: true,
                filter: true,
                lockPinned: true,
                resizable: true
            },
            style: {
                width: '100%',
                height: '644px'
                // height: '364px'
                // height: '2884px'
            },
            rowSelection: "single",
            paginationPageSize: 100,
            paginationNumberFormatter: function (params) {
                return params.value.toLocaleString();
            }
        };

        this.state.columnDefs[0].checkboxSelection = true;
        const self = this;

        // updated to return a React element, not a DOM node.
        this.state.columnDefs[0].cellRenderer = function (params) {
            if (params.value) {
                if (params.data[globalsUser.userEmail] !== undefined) {

                    return (
                        <a
                            href="#users"
                            onClick={(e) => {
                                e.preventDefault();
                                self.props.setUserToDuplicate(null);
                                self.props.history.push(
                                    '/' + self.props.cl +
                                    '/users/' + params.data[globalsUser.userEmail]
                                );
                            }}
                        >
                            {params.value}
                        </a>
                    );
                } else {
                    return (params.value);
                }
            }
            return null;
        };
    }

    onGridReady = params => {
        this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.props.setRowDataUsers(data);
            params.api.paginationGoToPage(0);
        };

        updateData(this.props.rowDataUsers);
        const value = this.props.searchBoxValue;
        document.getElementById('filter-text-box').value = value;
        params.api.setGridOption("quickFilterText", value);

        const valuePageSize = this.props.pageSize;
        document.getElementById("page-size").value = valuePageSize;
        this.gridApi.setGridOption("paginationPageSize", Number(valuePageSize));
    };

    onPageSizeChanged() {
        // const pxPerRow = 28;
        const value = document.getElementById("page-size").value;
        this.props.setPageSize(value);
        this.gridApi.setGridOption("paginationPageSize", Number(value));

        // const newHeight = ((Number(value) + 3) * pxPerRow).toString() + "px";
        // this.setHeight(newHeight);
    }

    onSelectionChanged() {
        this.setState({selectedUsers: this.gridApi.getSelectedRows()});
    }

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        if (this.props.rowDataUsers.length === 0) {
            try {
                const users = await this.users("");
                let tempResult = users;
                while (tempResult.LastEvaluatedKey) {
                    tempResult = await this.users(tempResult.LastEvaluatedKey);
                    users.Items = users.Items.concat(tempResult.Items);
                }
                let user;
                for (user of users.Items) {
                    if (user[globalsUser.userEmail] === this.props.currentUser.attributes.email) {
                        user[globalsUser.ordering] = true;
                    }
                }
                this.props.setRowDataUsers(users.Items);
            } catch (e) {
               handleApiErrorWithAlert(e);
            }
        }
        this.props.setUpdateTimeOutListener();
        this.setState({isLoading: false});
    }

    users(lastEvaluatedKey) {
        let myInit = {
            queryStringParameters: {
                clinicId: this.props.cl
            }
        };
        if (lastEvaluatedKey && ("" !== lastEvaluatedKey)) {
            myInit.queryStringParameters.ExclusiveStartKey = lastEvaluatedKey.clinicId;
            myInit.queryStringParameters.ExclusiveStartKey1 = lastEvaluatedKey.username;
        }
        return get("clinic", "/users", myInit);
    }

    onFilterTextBoxChanged() {
        if (this.gridApi) {
            const value = document.getElementById('filter-text-box').value;
            this.props.setSearchBox(value);
            this.gridApi.setGridOption("quickFilterText", value);
        }
    }

    onAddUser() {
        this.props.setUserToDuplicate(null);
        this.props.history.push('/' + this.props.cl + '/users/new');
    }

    onDuplicateUser() {
        this.props.setUserToDuplicate(this.state.selectedUsers);
        this.props.history.push('/' + this.props.cl + '/users/new');
    }

    onBtExport() {
        const params = {
            skipHeader: false,
            skipFooters: false,
            skipGroups: false,
            skipPinnedTop: false,
            skipPinnedBottom: false,
            allColumns: true,
            onlySelected: false,
            suppressQuotes: true,
            fileName: "CamAPS users",
            columnSeparator: ',',
            customHeader: "CamAPS users\n"
        };
        this.gridApi.exportDataAsCsv(params);
    }

    renderUsersList() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <div className="pump-container">
                    <div className="page-container">
                        {this.props.isEditor ?
                            <Button className="button-add pull-left" bsStyle="primary" bsSize="small"
                                    onClick={this.onAddUser.bind(this)}>
                                Add user
                            </Button> : null
                        }
                        {this.props.isEditor ?
                            <Button className="button-duplicate pull-left" bsStyle="primary" bsSize="small"
                                    margin-left={20}
                                    disabled={this.state.selectedUsers.length === 0}
                                    hidden={this.props.isEditor}
                                    onClick={this.onDuplicateUser.bind(this)}>
                                Duplicate user
                            </Button> : null
                        }
                        <Button className="pull-right" bsStyle="info" bsSize="small"
                                onClick={this.onBtExport.bind(this)}>
                            <Glyphicon glyph="file"/> Export to Excel
                        </Button>
                    </div>
                    <div className="page-container">
                        <div className="page-size-header pull-left">
                            Page size:&nbsp;
                            <select onChange={this.onPageSizeChanged.bind(this)} id="page-size" defaultValue={'100'}>
                                {/*<select id="page-size" defaultValue={'100'}>*/}
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                        <input className="pull-right" type="text" id="filter-text-box" placeholder="Search..."
                               onInput={this.onFilterTextBoxChanged.bind(this)}/>
                    </div>
                    <div style={{minHeight: "400px", height: "calc(100% - 30px)"}}>
                        <div
                            id="usersGrid"
                            style={this.state.style}
                            className="ag-theme-balham"
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowSelection={this.state.rowSelection}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                pagination={true}
                                paginationPageSize={this.state.paginationPageSize}
                                paginationNumberFormatter={this.state.paginationNumberFormatter}
                                rowData={this.props.rowDataUsers}
                                // headerHeight={58}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>Access not allowed. Not authenticated</h1>
            </div>
        );
    }

    getHeader() {
        if (this.state.isLoading) {
            return ('Loading ...');
        } else {
            return ((this.props.currentClinic ? this.props.currentClinic.clinicNameShort + ": " : "") +
                'Users with access to data')
        }
    }

    renderUsers() {
        return (
            <div className="pumps">
                {/*<div className="left-header pull-left">*/}
                <PageHeader>
                    <div className="header">
                        <div className="left-header pull-left">
                            {this.getHeader()}
                        </div>
                        <div className="clearfix"/>
                    </div>
                </PageHeader>
                {!this.state.isLoading && this.renderUsersList()}
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderUsers() : this.renderLander()}
            </div>
        );
    }
}
