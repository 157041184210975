/**
 * Pump SN attribute
 * @type {string}
 */
export const pumpSN = "pumpSN";

/**
 * Pump make attribute
 * @type {string}
 */
export const pumpMake = "pumpMake";

/**
 * Start date attribute
 * @type {string}
 */
export const startDate = "startDate";

/**
 * Whether the pump is in HCL pilot
 * @type {string}
 */
export const inHclPilot = "inHclPilot";

/**
 * User initials attribute
 * @type {string}
 */
export const userInitials = "userInitials";

/**
 * Whether free trial attribute
 * @type {string}
 */
export const freeTrial = "freeTrial";

/**
 * Supplier attribute
 * @type {string}
 */
export const supplier = "supplier";

/**
 * Whether order has been cancelled valid attribute
 * @type {string}
 */
export const orderCancelled = "orderCancelled";

/**
 * PO attribute
 * @type {string}
 */
export const codePO = "codePO";

/**
 * PO expiry date
 * @type {string}
 */
export const expiryDatePO = "expiryDatePO";

/**
 * User comment
 * @type {string}
 */
export const userComment = "userComment";
