import React, {Component, Fragment} from "react";
import {fetchAuthSession} from "aws-amplify/auth";
import {Link, withRouter} from "react-router-dom";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import Routes from "./Routes";
import "./App.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
// import {loadReCaptcha} from 'react-recaptcha-v3';
// import config from "./config";
import {getClinicDetails, getUserCanOrder, signOutFunction} from "./libs/user-lib";
import {Footer} from "./containers/Footer";

const store = require('store');
const STORE_KEY = 'lastAction';
const logoutTime = 30;  // in minutes
const warnTime = logoutTime - 1;    // in minutes

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            isEditor: false,
            isOrderer: false,
            isNewPasswordRequired: false,
            currentUser: null,
            currentUserClinicTable: null,
            currentClinic: null,
            cl: "",
            dayToDuplicate: null,
            userToDuplicate: null,
            orderToDuplicate: null,
            weekToDuplicate: null,
            monthToDuplicate: null,
            searchBoxValue: "",
            pageSize: '1000',
            dataSize: '5',
            dataSizeWeek: '5',
            dataSizeMonth: '5',
            captchaToken: null,
            captchaKeyEval: null,
            rowData: [],
            rowDataWeek: [],
            rowDataMonth: [],
            rowDataUsers: [],
            rowDataOrders: [],
        };

        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.logoutRedirect = this.logoutRedirect.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
        this.updateTimeOutListener();
        this.check();
    }

    clearTimeout() {
        if (this.warnTimeout) {
            clearTimeout(this.warnTimeout);
        }
        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
    }

    setTimeout() {
        this.warnTimeout = setTimeout(this.warn, warnTime * 20 * 1000);
        this.logoutTimeout = setTimeout(this.logout, logoutTime * 20 * 1000);
    }

    setTimeoutLogoutNow() {
        setTimeout(this.logoutRedirect, 0);
    }

    getLastAction() {
        return parseInt(store.get(STORE_KEY));
    }

    setLastAction() {
        store.set(STORE_KEY, Date.now());
    }

    check() {
        const now = Date.now();
        const timeLeft = this.getLastAction() + logoutTime * 60 * 1000;
        const diff = timeLeft - now;
        const isTimeout = diff < 0;
        console.log("Time left timer wait " + diff);
        if (isTimeout) {
            this.setTimeoutLogoutNow();
        }
    }

    async checkNoTimer() {
        const now = Date.now();
        const timeLeft = this.getLastAction() + logoutTime * 60 * 1000;
        const diff = timeLeft - now;
        const isTimeout = diff < 0;
        console.log("Time left timer no wait " + diff);
        if (isTimeout) {
            await this.logoutRedirect();
        }
    }

    updateTimeOutListener = () => {
        let i;
        for (i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.resetTimeout();
        this.setLastAction();
    };

    resetTimeout() {
        // console.log("Reset timeout...");
        this.clearTimeout();
        this.setTimeout();
    }

    warn() {
        // do not provide any warning - just logout
        // alert("You will be logged out automatically in 1 minute.");
    }

    async logout() {
        // Send a logout request to the API
        console.log("Sending a logout request to the API...");

        this.destroy(); // Cleanup
        await this.handleLogout();
    }

    async logoutRedirect() {
        // Send a logout request to the API
        console.log("Sending a logout request to the API...");

        this.destroy(); // Cleanup
        await this.handleLogoutRedirect();
    }

    destroy() {
        this.clearTimeout();
        let i;
        for (i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    async componentDidMount() {
        try {
            await this.checkNoTimer();
            this.setLastAction();
            const userInfo = await fetchAuthSession();
            if (userInfo.attributes && userInfo.attributes.email) {
                userInfo.attributes.email = userInfo.attributes.email.toLowerCase();
            } else {
                this.userHasAuthenticated(false);
                throw new Error("user not logged in");
            }
            this.setCurrentUser(userInfo);
            const pathnameSeg = this.props.location.pathname.split('/');
            let clinicId = "";
            if (pathnameSeg && (pathnameSeg.length > 1) && (pathnameSeg[1].length > 0)) {
                clinicId = pathnameSeg[1];
            }
            await getClinicDetails(this, this.state, this, userInfo, clinicId);
            await getUserCanOrder(this, this.state, userInfo.attributes.email);
            this.userHasAuthenticated(true);
            this.updateTimeOutListener();
        } catch (e) {
            // if ((e !== 'No current user') && (e !== 'Network Error')){
            //     alert(e);
            // }
        }

        this.setState({isAuthenticating: false});
    }

    userHasAuthenticated = authenticated => {
        if (authenticated) {
            this.resetTimeout();
        }
        this.setState({isAuthenticated: authenticated});
    };

    userNeedsNewPassword = newPassword => {
        this.setState({isNewPasswordRequired: newPassword});
    };

    setIsEditor = isEditor => {
        this.setState({isEditor: isEditor});
    };

    setIsOrderer = isOrderer => {
        this.setState({isOrderer: isOrderer});
    };

    setCurrentUser = currentUser => {
        this.setState({currentUser: currentUser});
    };

    setCurrentUserClinicTable = currentUserClinicTable => {
        this.setState({currentUserClinicTable: currentUserClinicTable});
    };

    setCurrentClinic = currentClinic => {
        this.setState({currentClinic: currentClinic});
        if (currentClinic) {
            this.setState({cl: currentClinic.clinicID});
        }
    };

    setCl = currentCl => {
        this.setState({cl: currentCl});
    };

    setUserToDuplicate = user => {
        this.setState({userToDuplicate: JSON.parse(JSON.stringify(user))});
    };

    setOrderToDuplicate = order => {
        this.setState({orderToDuplicate: JSON.parse(JSON.stringify(order))});
    };

    setDayToDuplicate = day => {
        this.setState({dayToDuplicate: JSON.parse(JSON.stringify(day))});
    };

    setWeekToDuplicate = week => {
        this.setState({weekToDuplicate: JSON.parse(JSON.stringify(week))});
    };

    setMonthToDuplicate = month => {
        this.setState({monthToDuplicate: JSON.parse(JSON.stringify(month))});
    };

    setSearchBox = searchValue => {
        this.setState({searchBoxValue: searchValue});
    };

    setPageSize = pageSize => {
        this.setState({pageSize: pageSize});
    };

    setDataSize = dataSize => {
        this.setState({dataSize: dataSize});
    };

    setDataSizeWeek = dataSize => {
        this.setState({dataSizeWeek: dataSize});
    };

    setDataSizeMonth = dataSize => {
        this.setState({dataSizeMonth: dataSize});
    };

    setCaptchaToken = captchaToken => {
        this.setState({captchaToken: captchaToken});
    };

    setCaptchaKeyEval = captchaKeyEval => {
        this.setState({captchaKeyEval: captchaKeyEval});
    };

    getNewCaptchaToken = captchaKeyEval => {
        this.setState({captchaKeyEval: Math.random()});
    };

    setRowData = rowData => {
        this.setState({rowData: rowData});
    };

    setRowDataUsers = rowDataUsers => {
        this.setState({rowDataUsers: rowDataUsers});
    };

    setRowDataOrders = rowDataOrders => {
        this.setState({rowDataOrders: rowDataOrders});
    };

    setRowDataWeek = rowDataWeek => {
        this.setState({rowDataWeek: rowDataWeek});
    };

    setRowDataMonth = rowDataMonth => {
        this.setState({rowDataMonth: rowDataMonth});
    };

    handleLogout = async event => {
        this.props.history.push("/login");
        await signOutFunction(this, this);
    };

    handleLogoutRedirect = async event => {
        await signOutFunction(this, this);
    };

    getUser() {
        try {
            return (this.state.currentUserClinicTable.adminName);
        } catch (e) {
            return ('');
        }
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            isNewPasswordRequired: this.state.isNewPasswordRequired,
            userNeedsNewPassword: this.userNeedsNewPassword,
            isEditor: this.state.isEditor,
            setIsEditor: this.setIsEditor,
            isOrderer: this.state.isOrderer,
            setIsOrderer: this.setIsOrderer,
            currentUser: this.state.currentUser,
            setCurrentUser: this.setCurrentUser,
            currentUserClinicTable: this.state.currentUserClinicTable,
            setCurrentUserClinicTable: this.setCurrentUserClinicTable,
            currentClinic: this.state.currentClinic,
            setCurrentClinic: this.setCurrentClinic,
            cl: this.state.cl,
            setCl: this.setCl,
            dayToDuplicate: this.state.dayToDuplicate,
            setDayToDuplicate: this.setDayToDuplicate,
            userToDuplicate: this.state.userToDuplicate,
            setUserToDuplicate: this.setUserToDuplicate,
            orderToDuplicate: this.state.orderToDuplicate,
            setOrderToDuplicate: this.setOrderToDuplicate,
            weekToDuplicate: this.state.weekToDuplicate,
            setWeekToDuplicate: this.setWeekToDuplicate,
            monthToDuplicate: this.state.monthToDuplicate,
            setMonthToDuplicate: this.setMonthToDuplicate,
            searchBoxValue: this.state.searchBoxValue,
            setSearchBox: this.setSearchBox,
            pageSize: this.state.pageSize,
            setPageSize: this.setPageSize,
            dataSize: this.state.dataSize,
            setDataSize: this.setDataSize,
            dataSizeWeek: this.state.dataSizeWeek,
            setDataSizeWeek: this.setDataSizeWeek,
            dataSizeMonth: this.state.dataSizeMonth,
            setDataSizeMonth: this.setDataSizeMonth,
            captchaToken: this.state.captchaToken,
            setCaptchaToken: this.setCaptchaToken,
            captchaKeyEval: this.state.captchaKeyEval,
            setCaptchaKeyEval: this.setCaptchaKeyEval,
            rowData: this.state.rowData,
            setRowData: this.setRowData,
            rowDataUsers: this.state.rowDataUsers,
            setRowDataUsers: this.setRowDataUsers,
            rowDataOrders: this.state.rowDataOrders,
            setRowDataOrders: this.setRowDataOrders,
            rowDataWeek: this.state.rowDataWeek,
            setRowDataWeek: this.setRowDataWeek,
            rowDataMonth: this.state.rowDataMonth,
            setRowDataMonth: this.setRowDataMonth,
            setUpdateTimeOutListener: this.updateTimeOutListener,
            logoutNow: this.logoutRedirect,
            getNewCaptchaToken: this.getNewCaptchaToken,
        };

        return (
            !this.state.isAuthenticating &&
            <div className="App container-fluid">
                <Navbar fluid collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link to="/">CamAPS FX Clinic</Link>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            {this.state.isAuthenticated && (!this.state.isNewPasswordRequired)
                                ? <Fragment>
                                    <LinkContainer to={"/" + this.state.cl + "/settings"}>
                                        <NavItem>Settings</NavItem>
                                    </LinkContainer>
                                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                                </Fragment>
                                : <Fragment>
                                    {/*<LinkContainer to="/signup">*/}
                                    {/*    <NavItem>Signup</NavItem>*/}
                                    {/*</LinkContainer>*/}
                                    <LinkContainer to="/login">
                                        <NavItem>Login</NavItem>
                                    </LinkContainer>
                                </Fragment>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {this.state.isAuthenticated && (!this.state.isNewPasswordRequired) &&
                <Navbar fluid collapseOnSelect inverse>
                    <Navbar.Header>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    {this.state.isAuthenticated &&
                    <Navbar.Collapse>
                        <Nav pullLeft>
                            <Fragment>
                                <LinkContainer className="" to={"/" + this.state.cl + "/day"}>
                                    <NavItem>Day</NavItem>
                                </LinkContainer>
                                <LinkContainer className="" to={"/" + this.state.cl + "/week"}>
                                    <NavItem>Week</NavItem>
                                </LinkContainer>
                                <LinkContainer className="" to={"/" + this.state.cl + "/month"}>
                                    <NavItem>Month</NavItem>
                                </LinkContainer>
                                <LinkContainer className=""
                                               to={"/" + this.state.cl + "/info/" + this.state.currentUser.attributes.email}>
                                    <NavItem>My info</NavItem>
                                </LinkContainer>
                                {this.state.isEditor &&
                                <LinkContainer to={"/" + this.state.cl + "/users"}>
                                    <NavItem>Clinic users</NavItem>
                                </LinkContainer>
                                }
                                {this.state.isOrderer &&
                                <LinkContainer to={"/" + this.state.cl + "/orders"}>
                                    <NavItem>Dana orders</NavItem>
                                </LinkContainer>
                                }
                            </Fragment>
                        </Nav>
                        <Nav pullRight>
                            <Navbar.Text className="justify-content-end">
                                {this.getUser() + ": " + this.state.cl}
                            </Navbar.Text>
                        </Nav>
                    </Navbar.Collapse>
                    }
                </Navbar>
                }
                <Routes childProps={childProps}/>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(App);
