// Threshold values
import * as globals from "./globalDef";

const dangerTiR = 40;
const warningTiR = 60;
const dangerTbR = 10;
const warningTbR = 5;
const dangerGlucose = 15;
const warningGlucose = 10;
const dangerClUse = 50;
const warningClUse = 70;
const dangerAlertPerDay = 20;
const warningAlertPerDay = 10;
const dangerAlertPerNight = 10;
const warningAlertPerNight = 5;
const dangerBolus = 15;
const warningBolus = 30;

const regExpNumber = /\d*\.?,?\d*/;
// Whether performance cell formatting should be used
const useCellFormatting = true;

const warningFormat = 'my-amber';
const dangerFormat = 'my-red';
const okFormat = '';

/**
 * Generate cell format of table for time in range
 * @param params Value to determine cell formatting
 * @returns {string} Cell formatting string (normal, warning, and danger)
 */
export function olineTiR(params) {
    let formatCell = '';
    try {
        const value = params.value.match(regExpNumber);
        if ((value.input !== 'na') && useCellFormatting) {
            if (value < dangerTiR) {
                formatCell = dangerFormat;
            } else if (value < warningTiR) {
                formatCell = warningFormat;
            }
        }
    } catch (e) {
    }
    return formatCell;
}


/**
 * Generate cell format of table for closed loop use
 * @param params Value to determine cell formatting
 * @returns {string} Cell formatting string (normal, warning, and danger)
 */
export function olineClUse(params) {
    let formatCell = okFormat;
    try {
        const value = params.value.match(regExpNumber);
        if ((value.input !== 'na') && useCellFormatting) {
            if (value < dangerClUse) {
                formatCell = dangerFormat;
            } else if (value < warningClUse) {
                formatCell = warningFormat;
            }
        }
    } catch (e) {
    }
    return formatCell;
}

/**
 * Generate cell format of table for alerts per day
 * @param param Value to determine cell formatting
 * @returns {string} Cell formatting string (normal, warning, and danger)
 */
export function olineAlerts(param) {
    let formatCell = okFormat;
    try {
        let value = param.value.match(regExpNumber);
        if ((value.input !== 'na') && useCellFormatting) {
            value = value / param.data.daysPerRecord;
            if (value > dangerAlertPerDay) {
                formatCell = dangerFormat;
            } else if (value > warningAlertPerDay) {
                formatCell = warningFormat;
            }
        }
    } catch (e) {
        console.log(e.toString());
    }
    return formatCell;
}

/**
 * Generate cell format of table for alerts per day
 * @param param Value to determine cell formatting
 * @returns {string} Cell formatting string (normal, warning, and danger)
 */
export function olineAlertsNight(param) {
    let formatCell = okFormat;
    try {
        let value = param.value.match(regExpNumber);
        if ((value.input !== 'na') && useCellFormatting) {
            value = value / param.data.daysPerRecord;
            if (value > dangerAlertPerNight) {
                formatCell = dangerFormat;
            } else if (value > warningAlertPerNight) {
                formatCell = warningFormat;
            }
        }
    } catch (e) {
        console.log(e.toString);
    }
    return formatCell;
}

/**
 * Generate cell format of table for time below range
 * @param param Value to determine cell formatting
 * @returns {string} Cell formatting string (normal, warning, and danger)
 */
export function olineTbR(param) {
    let formatCell = okFormat;
    try {
        const value = param.value.replace(/,/g, '.').match(regExpNumber);
        if ((value.input !== 'na') && useCellFormatting) {
            if (value > dangerTbR) {
                formatCell = dangerFormat;
            } else if (value > warningTbR) {
                formatCell = warningFormat;
            }
        }
    } catch (e) {
    }
    return formatCell;
}

/**
 * Generate cell format of table for mean glucose
 * @param params Value to determine cell formatting
 * @returns {string} Cell formatting string (normal, warning, and danger)
 */
export function olineGlucose(params) {
    let formatCell = okFormat;
    try {
        const conver = 0.05551;
        let value = params.value.match(regExpNumber);
        if ((value.input !== 'na') && useCellFormatting) {
            if (params.value.includes("mg")) {
                value *= conver;
            }
            if (value > dangerGlucose) {
                formatCell = dangerFormat;
            } else if (value > warningGlucose) {
                formatCell = warningFormat;
            }
        }
    } catch (e) {
    }
    return formatCell;
}

/**
 * Generate cell format of table for basal inuslin
 * @param params Bolus value to determine cell formatting
 * @returns {string} Cell formatting string (normal, warning, and danger)
 */
export function olineBolusInsulin(params) {
    let formatCell = okFormat;
    try {
        const valueBolus = parseFloat(params.value.match(regExpNumber));
        const valueBasal = parseFloat(params.data[globals.insulinBasal].match(regExpNumber));
        if ((!isNaN(valueBolus)) && (!isNaN(valueBasal)) && useCellFormatting) {
            const valueBolusPercentage = 100.0 * (valueBolus / (valueBasal + valueBolus));
            if (valueBolusPercentage < dangerBolus) {
                formatCell = dangerFormat;
            } else if (valueBolusPercentage < warningBolus) {
                formatCell = warningFormat;
            }
        }
    } catch (e) {
    }
    return formatCell;
}