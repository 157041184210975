
const dev = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "camdiab.bug.report"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://03fbchsu9l.execute-api.eu-west-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_oJdSwIpDu",
        APP_CLIENT_ID: "2i21j43u2otdqnj02hijvrt5ir",
        IDENTITY_POOL_ID: "eu-west-1:b23e8dd9-28cf-48e1-80f4-4a20ecdbbc7d"
    },
    captchaSiteKey : "6Lcd6c0UAAAAAOie2PgUb2e0ZVYOs78GIm3AvRcM"
};

const prod = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "camdiab.bug.report"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://pch4ganej1.execute-api.eu-west-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_kIfwEEtQ1",
        APP_CLIENT_ID: "7v6mobaju6g94mbcc32572c8r2",
        IDENTITY_POOL_ID: "eu-west-1:9f9c0d20-3e22-4163-beb4-7603dc4fb47b"
    },

    captchaSiteKey : "6LcF6c0UAAAAANZRICB21bxDvj82zdBZ95NNttwV"
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

// eslint-disable-next-line
export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
