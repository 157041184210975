import * as globalsOrder from "./globalDefOrdering";

/**
 * Shared ag-grid column definition for orders
 * @type {*[]}
 */
export const columnDefsOrders = [{
    headerName: "Pump SN", field: globalsOrder.pumpSN,
    pinned: 'left',
    width: 150
}, {
    headerName: "Pump make", field: globalsOrder.pumpMake,
    width: 100
}, {
    headerName: "CamAPS FX supplier", field: globalsOrder.supplier,
    width: 120
}, {
    headerName: "Patient initials", field: globalsOrder.userInitials,
    width: 120,
}, {
    headerName: "Start date", field: globalsOrder.startDate,
    width: 120
// }, {
//     headerName: "30-day free trial", field: globalsOrder.freeTrial,
//     width: 130,
//     cellStyle: {textAlign: 'center'},
//     cellRenderer: function (params) {
//         const input = document.createElement('input');
//         input.type = "checkbox";
//         input.checked = params.value;
//         input.checked = params.value && (params.value !== "false");
//         input.disabled = true;
//         return input;
//     }
}, {
    headerName: "PO details", field: globalsOrder.codePO,
    width: 160
}, {
    headerName: "PO expiry", field: globalsOrder.expiryDatePO,
    width: 140
}, {
    headerName: "Order cancelled", field: globalsOrder.orderCancelled,
    width: 130,
    cellStyle: {textAlign: 'center'},
    cellRenderer: checkBoxRenderer
}, {
    headerName: "Additional information/requirement", field: globalsOrder.userComment,
    width: 650
},
];

// render a checkbox (not editable)
function checkBoxRenderer(params) {
    return (
        <input
            type="checkbox"
            checked={params.value && params.value !== "false"}
            disabled={true}
            onChange={(event) => {
                const updatedValue = event.target.checked;
                params.node.setDataValue(params.column.colId, updatedValue);
                params.node.data.enabled = updatedValue;
                //   params.api.refreshCells({ rowNodes: [params.node] });
            }}
        />
    );
}

// render a checkbox (editable)
function checkBoxRendererEditable(params) {
    return (
        <input
            type="checkbox"
            checked={params.value && params.value !== "false"}
            disabled={false}
            onChange={(event) => {
                const updatedValue = event.target.checked;
                params.node.setDataValue(params.column.colId, updatedValue);
                params.node.data.enabled = updatedValue;
                //   params.api.refreshCells({ rowNodes: [params.node] });
            }}
        />
    );
}


/**
 * Adds to columns the field whether pump is HCL pilot
 * @param columns
 */
export function addHclPilotField(columns) {

    const hclColumn = {
        headerName: "HCL pilot", field: globalsOrder.inHclPilot,
        width: 130,
        cellStyle: {textAlign: 'center'},
        cellRenderer: checkBoxRenderer
    };

    for (let i = 0; i < columns.length; i++) {
        if ((columns[i].field === globalsOrder.startDate) &&
            (columns[i + 1].field !== globalsOrder.inHclPilot)) {
            columns.splice(i + 1, 0, hclColumn);
            break;
        }
    }
}

/**
 * Update column definition for data entry
 */
export function setColDefOrderForDataEntry(editing, self, updating) {
    let field;
    let i;

    for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
        field = self.state.columnDefs[i];

        if (field.field === globalsOrder.orderCancelled) {
            self.state.columnDefs.splice(i);
        } else if (field.field === globalsOrder.codePO) {
            self.state.columnDefs.splice(i);
        } else if (field.field === globalsOrder.expiryDatePO) {
            self.state.columnDefs.splice(i);
        } else if (field.field === globalsOrder.freeTrial) {
            field.cellRenderer = checkBoxRendererEditable;
        } else if (field.field === globalsOrder.inHclPilot) {
            field.cellRenderer = checkBoxRendererEditable;
        } else if (field.field === globalsOrder.pumpMake) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['Dana-i', 'Dana RS']
            };
        } else if (field.field === globalsOrder.supplier) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['CamDiab']
            };
        }

        if (editing) {
            if ((field.field !== globalsOrder.pumpSN) &&
                (field.field !== globalsOrder.inHclPilot) &&
                (field.field !== globalsOrder.freeTrial) &&
                (field.field !== globalsOrder.codePO) &&
                (field.field !== globalsOrder.userComment) &&
                (field.field !== globalsOrder.orderCancelled)) {
                field.editable = true;
            }
        } else {
            if ((field.field !== globalsOrder.freeTrial) &&
                (field.field !== globalsOrder.inHclPilot) &&
                (field.field !== globalsOrder.codePO) &&
                (field.field !== globalsOrder.userComment) &&
                (field.field !== globalsOrder.orderCancelled)) {
                field.editable = true;
            }
        }
    }

    self.state.columnDefs.push({
        headerName: "PO details (optional)", field: globalsOrder.codePO,
        width: 160,
        editable: true,
    });

    self.state.columnDefs.push({
        headerName: "Additional information/requirements", field: globalsOrder.userComment,
        width: 650,
        editable: true,
    });
}
