import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import "./NewUser.css";
import {handleApiErrorWithAlert, postWrapper as post} from "../libs/awsApiWrappers";
import * as globalsUsers from "../libs/globalDefUsers";
import * as userLib from "../libs/user-lib";
import {AgGridReact} from "ag-grid-react";
import {columnDefsUsers, setColDefUserForDataEntry} from "../libs/columnDefUsers";
import {validateFormUser} from "../libs/user-lib";

export default class NewUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            selectedUsers: [],
            clinicId: this.props.cl,
            currentClinic: this.props.currentClinic,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsUsers)),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '105px'
            },
            rowDataUsers: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        setColDefUserForDataEntry(false, this, false);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowDataUsers: data});
        };

        updateData(this.state.rowDataUsers);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        if (this.props.userToDuplicate && this.props.userToDuplicate.length > 0) {
            this.setState({rowDataUsers: [this.props.userToDuplicate[0]]});
        } else {
            const obj = [{
                [globalsUsers.userEmail]: userLib.defaultUser,
                [globalsUsers.weekly]: "true",
                [globalsUsers.monthly]: "true",
                [globalsUsers.enabled]: "true"
            }];
            this.setState({rowDataUsers: obj});
        }

        this.props.setUpdateTimeOutListener();
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.gridApi.stopEditing();
        if (validateFormUser(this)) {
            this.setState({isLoading: true});

            try {
                const element = this.gridApi.getDisplayedRowAtIndex(0).data;

                await this.createUser(element);
                // reload data
                this.props.setRowDataUsers([]);
                this.props.history.push("/" + this.state.clinicId + "/users");
            } catch (e) {
                handleApiErrorWithAlert(e);

                this.setState({ isLoading: false });
            }
        }
    };

    createUser(user) {
        const object = {
            clinicId: this.state.clinicId,
            email: user[globalsUsers.userEmail].trim(),
            name: user[globalsUsers.userName].trim(),
            enabled: user[globalsUsers.enabled],
            daily: user[globalsUsers.daily],
            weekly: user[globalsUsers.weekly],
            monthly: user[globalsUsers.monthly],
            ordering: user[globalsUsers.ordering],
            clinicName: this.state.currentClinic.clinicName,
            clinicNameShort: this.state.currentClinic.clinicNameShort,
            locale: "EN",
        };
        return post("clinic", "/users", {
            body: object
        });
    }

    render() {
        return (
            <div className="NewUser">
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            components={this.state.components}
                            rowData={this.state.rowDataUsers}
                            // headerHeight={58}
                        />
                    </div>

                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        // disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create"
                        loadingText="Creating…"
                    />
                </form>
            </div>
        );
    }
}
