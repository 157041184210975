import React, {Component} from "react";
import {getWrapper as get, putWrapper as put, delWrapper as del, handleApiErrorWithAlert} from "../libs/awsApiWrappers";
import LoaderButton from "../components/LoaderButton";
import "./Order.css";
import {AgGridReact} from "ag-grid-react";
import {addHclPilotField, columnDefsOrders, setColDefOrderForDataEntry} from "../libs/columnDefOrdering";
import {validateFormOrder} from "../libs/user-lib";
import * as globalsOrders from "../libs/globalDefOrdering";

export default class Order extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            order: null,
            clinicId: this.props.cl,
            currentClinic: this.props.currentClinic,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsOrders)),

            // store if site is HCL pilot site
            hclSite: (this.props.currentClinic.hclPilot === true) ||
                (this.props.currentClinic.hclPilot === "true"),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '105px'
            },

            // components: {datePicker: this.getDatePicker()},
            rowDataOrders: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // add hcl pilot column if appropriate
        if (this.state.hclSite) {
            addHclPilotField(this.state.columnDefs);
            // make a copy of the JSON structure as the underlying structure is modified
            this.setState({columnDefs: JSON.parse(JSON.stringify(this.state.columnDefs))});
        }

        // update column definition
        setColDefOrderForDataEntry(true, this, false);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowDataOrders: data});
        };

        updateData(this.state.rowDataOrders);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    async componentDidMount() {
        try {
            const order = await this.getOrder();
            this.setState({rowDataOrders: [order]});
        } catch (e) {
          handleApiErrorWithAlert(e);
        }
        this.props.setUpdateTimeOutListener();
    }

    getOrder() {
        let myInit = {
            queryStringParameters: {
                clinicId: this.state.clinicId,
            }
        };
        return get("clinic", `/orders/${this.props.match.params.id}`, myInit);
    }

    saveOrder(order, reset) {
        const object = {
            clinicId: this.state.clinicId,
            pumpSN: order[globalsOrders.pumpSN],
            pumpMake: order[globalsOrders.pumpMake],
            startDate: order[globalsOrders.startDate],
            inHclPilot: order[globalsOrders.inHclPilot],
            userInitials: order[globalsOrders.userInitials],
            ordererName: this.props.currentUserClinicTable.adminName,
            freeTrial: order[globalsOrders.freeTrial],
            userComment: order[globalsOrders.userComment],
            codePO: order[globalsOrders.codePO],
            supplier: order[globalsOrders.supplier],
            currentClinic : this.state.currentClinic.clinicNameShort,
            locale: "EN",
        };
        return put("clinic", `/orders/${this.props.match.params.id}`, {
            body: object
        });
    }

    cancelOrder(order) {
        let myInit = {
            queryStringParameters: {
                pumpSN: order[globalsOrders.pumpSN],
                clinicId: this.state.clinicId,
                ordererName: this.props.currentUserClinicTable.adminName,
                currentClinic : this.props.currentClinic.clinicNameShort,
            }
        };
        return del("clinic", `/orders/${this.props.match.params.id}`, myInit);
    }

    handleSubmit = async event => {

        event.preventDefault();
        this.gridApi.stopEditing();
        if (validateFormOrder(this, true)) {
            this.setState({isLoading: true});

            try {
                const element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.saveOrder(element, false);
                // reload data
                this.props.setRowDataOrders([]);
                this.props.history.push("/" + this.props.cl + "/orders");
            } catch (e) {
                handleApiErrorWithAlert(e);
                this.setState({isLoading: false});
            }
        }
    };

    handleCancel = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to cancel this order? This cannot be undone."
        );

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            const element = this.gridApi.getDisplayedRowAtIndex(0).data;
            await this.cancelOrder(element);
            // reload data
            this.props.setRowDataOrders([]);
            this.props.history.push("/" + this.props.cl + "/orders");
        } catch (e) {
            handleApiErrorWithAlert(e);
            this.setState({isDeleting: false});
        }
    };

    render() {
        return (
            <div className="User">
                {this.state.rowDataOrders &&
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            // components={this.state.components}
                            rowData={this.state.rowDataOrders}
                            // headerHeight={58}
                        />
                    </div>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleCancel}
                        text="Cancel order"
                        loadingText="Cancelling order…"
                    />
                </form>}
            </div>
        );
    }
}
