import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import "./NewOrder.css";
import * as globalsOrders from "../libs/globalDefOrdering";
import * as userLib from "../libs/user-lib";
import {AgGridReact} from "ag-grid-react";
import {columnDefsOrders, setColDefOrderForDataEntry, addHclPilotField} from "../libs/columnDefOrdering";
import {validateFormOrder} from "../libs/user-lib";
import {postWrapper as post, handleApiErrorWithAlert} from "../libs/awsApiWrappers";

export default class NewOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            selectedOrders: [],
            clinicId: this.props.cl,
            currentClinic: this.props.currentClinic,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsOrders)),

            defaultColDef: {
                resizable: true
            },

            // store if site is HCL pilot site
            hclSite: (this.props.currentClinic.hclPilot === true) ||
                (this.props.currentClinic.hclPilot === "true"),

            style: {
                width: '100%',
                // height: '364px'
                height: '105px'
            },
            rowDataOrders: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // add hcl pilot column if appropriate
        if (this.state.hclSite) {
            addHclPilotField(this.state.columnDefs);
            // make a copy of the JSON structure as the underlying structure is modified
            this.setState({columnDefs: JSON.parse(JSON.stringify(this.state.columnDefs))});
        }
        // update column definition
        setColDefOrderForDataEntry(false, this, false);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowDataOrders: data});
        };

        updateData(this.state.rowDataOrders);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        if (this.props.orderToDuplicate && this.props.orderToDuplicate.length > 0) {
            this.setState({rowDataOrders: [this.props.orderToDuplicate[0]]});
        } else {
            const obj = [{
                [globalsOrders.pumpSN]: userLib.defaultSN,
                [globalsOrders.startDate]: new Date().toISOString().substring(0, 10),
            }];
            this.setState({rowDataOrders: obj});
        }

        this.props.setUpdateTimeOutListener();
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.gridApi.stopEditing();
        if (validateFormOrder(this, false)) {
            const confirmed = window.confirm(
                "Press OK to place the order"
            );

            if (!confirmed) {
                return;
            }

            this.setState({isLoading: true});

            try {
                const element = this.gridApi.getDisplayedRowAtIndex(0).data;

                await this.submitOrder(element);
                // reload data
                this.props.setRowDataOrders([]);
                this.props.history.push("/" + this.state.clinicId + "/orders");
            } catch (e) {
                if (handleApiErrorWithAlert(e)) {
                    console.log("Logging out due to fatal error");
                    this.props.logoutNow();
                }
                this.setState({isLoading: false});
            }
        }
    };

    submitOrder(order) {
        const object = {
            clinicId: this.state.clinicId,
            pumpSN: order[globalsOrders.pumpSN].toUpperCase().trim(),
            pumpMake: order[globalsOrders.pumpMake],
            startDate: order[globalsOrders.startDate],
            inHclPilot: order[globalsOrders.inHclPilot],
            userInitials: order[globalsOrders.userInitials],
            ordererName: this.props.currentUserClinicTable.adminName,
            freeTrial: order[globalsOrders.freeTrial],
            userComment: order[globalsOrders.userComment],
            supplier: order[globalsOrders.supplier],
            codePO: order[globalsOrders.codePO],
            currentClinic: this.state.currentClinic.clinicNameShort,
            locale: "EN",
        };
        return post("clinic", "/orders", {
            body: object
        });
    }

    render() {
        return (
            <div className="NewUser">
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            components={this.state.components}
                            rowData={this.state.rowDataOrders}
                            // headerHeight={58}
                        />
                    </div>

                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        // disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Place order"
                        loadingText="Placing order…"
                    />
                </form>
            </div>
        );
    }
}
