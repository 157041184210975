import * as globals from "./globalDef";
import {
    olineAlerts,
    olineAlertsNight,
    olineBolusInsulin,
    olineClUse,
    olineGlucose,
    olineTbR,
    olineTiR,
} from "./formatting-lib";

/**
 * Shared ag-grid column definition
 * @type {*[]}
 */
export const columnDefs = [{
    headerName: "User email", field: globals.userNameNew,
    pinned: 'left',
    width: 200,
}, {
    headerName: "User name", field: globals.nameNew,
    width: 150,
}, {
    headerName: "Pump SN", field: globals.pumpSN,
    width: globals.standardColWidth
}, {
    headerName: "Start date", field: globals.endDate,
    width: globals.standardColWidth,
}, {
    headerName: "CGM use", field: globals.availabilityCgm,
    width: globals.standardColWidth,
}, {
    headerName: "CL use*", field: globals.availabilityCl,
    headerTooltip: "Yellow: below 70% <br>Red: below 50%",
    width: globals.standardColWidth,
    cellClass: olineClUse
}, {
    headerName: "Mean CGM*", field: globals.cgmMean,
    headerTooltip: "Yellow: above 10 mmol/L (180 mg/dL)<br>Red: above 15 mmol/L (270 mg/dL)",
    width: globals.standardColWidth,
    cellClass: olineGlucose
}, {
    headerName: "GMI", field: globals.gmiPercent,
    width: globals.standardColWidth
}, {
    headerName: "CGM SD", field: globals.cgmVarSD,
    width: globals.standardColWidth,
}, {
    headerName: "CGM CV", field: globals.cgmVarCV,
    width: globals.standardColWidth,
}, {
    headerName: "In target*", field: globals.cgmTargetIn,
    headerTooltip: "Yellow: below 60% <br>Red: below 40%",
    width: globals.standardColWidth,
    cellClass: olineTiR
}, {
    headerName: "Below target*", field: globals.cgmTargetBelow,
    headerTooltip: "Yellow: above 5% <br>Red: above 10%",
    width: globals.standardColWidth,
    cellClass: olineTbR
}, {
    headerName: "Above target", field: globals.cgmTargetAbove,
    width: globals.standardColWidth,
}, {
    headerName: "# hypos", field: globals.hyposNumber,
    width: globals.standardColWidth,
}, {
    headerName: "Avg hypo duration", field: globals.hyposDuration,
    width: globals.standardColWidth,
}, {
    headerName: "TDD", field: globals.insulin,
    width: globals.standardColWidth,
}, {
    headerName: "TD bolus*", field: globals.insulinBolus,
    headerTooltip: "Yellow: below 30% TDD <br>Red: below 15% TDD",
    width: 140,
    cellClass: olineBolusInsulin
}, {
    headerName: "TD basal", field: globals.insulinBasal,
    width: 140,
}, {
    headerName: "# pump fill", field: globals.refillNumber,
    width: globals.narrowColWidth,
}, {
    headerName: "# cannula prime", field: globals.primeNumber,
    width: globals.narrowColWidth,
}, {
    headerName: "# sensor insert", field: globals.sensorInsert,
    width: globals.narrowColWidth,
}, {
    headerName: "CL interrupts", field: globals.noClAll,
    width: 100,
}, {
    headerName: "CL no sensor", field: globals.noClCgm,
    width: globals.narrowColWidth,
}, {
    headerName: "CL no pump", field: globals.noClPump,
    width: globals.narrowColWidth,
}, {
    headerName: "CL pump suspended", field: globals.noClSuspend,
    width: 100,
}, {
    headerName: "CL TDD exceeded", field: globals.noClTddLimit,
    width: globals.narrowColWidth,
}, {
    headerName: "CL other", field: globals.noClOther,
    width: globals.narrowColWidth,
}, {
    headerName: "CL no BT", field: globals.noClBt,
    width: globals.narrowColWidth,
}, {
    headerName: "# sound alerts*", field: globals.alertSound,
    headerTooltip: "Yellow: above 10 alerts per day-and-night <br>Red: above 20 alerts per day-and-night",
    width: globals.narrowColWidth,
    cellClass: olineAlerts
}, {
    headerName: "# sound night alerts*", field: globals.alertSoundNight,
    headerTooltip: "Yellow: above 5 alerts per night <br>Red: above 10 alerts per night",
    width: globals.narrowColWidth,
    cellClass: olineAlertsNight
}, {
    headerName: "# vibrate alerts", field: globals.alertVibrate,
    width: globals.narrowColWidth,
}, {
    headerName: "# vibrate night alerts", field: globals.alertVibrateNight,
    width: globals.narrowColWidth,
// }, {
//     headerName: "Display target low", field: globals.thresholdLow,
//     width: globals.standardColWidth,
// }, {
//     headerName: "Display target high", field: globals.thresholdHigh,
//     width: globals.standardColWidth,
}, {
    headerName: "Display target", field: globals.displayThreshold,
    width: 180,
}, {
    headerName: "", field: globals.combined,
    width: 1,
},
];

const conver = 0.05551;

export function comVal(a, b) {
    const aMmol = a.includes("mmol/L");
    const bMmol = b.includes("mmol/L");
    try {
        if ((aMmol && bMmol) || ((!aMmol) && (!bMmol))) {
            return (a.length < b.length) && (a !== 'na') ? -1 : ((a.length > b.length) && (b !== 'na') ? 1 : a.localeCompare(b));
        } else {
            if ((!aMmol) && (a !== 'na')) {
                a = parseFloat(a) * conver;
                b = parseFloat(b.replace(/,/g, '.'));
            } else if ((!bMmol) && (b !== 'na')) {
                a = parseFloat(a.replace(/,/g, '.'));
                b = parseFloat(b) * conver;
            }
            return a < b ? -1 : a > b ? 1 : 0;
        }
    } catch (e) {
        return -1;
    }
}

//
// /**
//  * Update column definition for data entry
//  */
// export function setColDefForDataEntry(editting, self) {
// // remove items which not entered
//     let field;
//     let i;
//     for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
//         field = self.state.columnDefs[i];
//         if ((field.field === globals.createdAt) ||
//             (field.field === globals.modifiedAt) ||
//             (field.field === globals.userEmail)) {
//             self.state.columnDefs.splice(i, 1);
//         } else if (field.field === globals.variant) {
//             field.cellEditor = "agSelectCellEditor";
//             field.cellEditorParams = {
//                 values: ['FX', 'HX', 'FX HX']
//             };
//         } else if (field.field === globals.isTrained) {
//             field.cellEditor = "agSelectCellEditor";
//             field.cellEditorParams = {
//                 values: ['false', 'true']
//             };
//             field.cellRenderer = function (params) {
//                 if ((!params.value) || (params.value === false)) {
//                     params.value = "false";
//                 }
//                 return params.value;
//             }
//         } else if (field.field === globals.pumpMake) {
//             field.cellEditor = "agSelectCellEditor";
//             field.cellEditorParams = {
//                 values: ['Dana RS', 'Dana R']
//             };
//         } else if (field.field === globals.study) {
//             field.cellEditor = "agSelectCellEditor";
//             field.cellEditorParams = {
//                 values: ['CLOuD', 'KidsAP02', 'Dan05', 'Dan06', 'AiDAPT', 'APhome04', 'AP-Renal', 'Other', 'None']
//             };
//         } else if (field.field === globals.currency) {
//             field.cellEditor = "agSelectCellEditor";
//             field.cellEditorParams = {
//                 values: ['GBP', 'EUR']
//             };
//         } else if (field.field === globals.commercial) {
//             field.cellEditor = "agSelectCellEditor";
//             field.cellEditorParams = {
//                 values: ['false', 'true']
//             };
//         } else if (field.field === globals.costModel) {
//             field.cellEditor = "agSelectCellEditor";
//             field.cellEditorParams = {
//                 values: ['monthly', 'six monthly', 'annual']
//             };
//         } else if ((field.field === globals.startDate) || (field.field === globals.expiryDate)) {
//             // field.cellEditor = "datePicker";
//             field.onCellValueChanged = self.recalculateRemainingDays;
//         }
//         if (field.field !== globals.remainingDays) {
//             if (editting) {
//                 if (field.field !== globals.pumpSN) {
//                     field.editable = true;
//                 }
//             } else {
//                 field.editable = true;
//             }
//         }
//     }
// }
