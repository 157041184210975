/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * User email
 * @type {string}
 */
export const userEmail = "username" + attrib_suffix;

/**
 * User name attribute
 * @type {string}
 */
export const userName = "adminName" + attrib_suffix;

/**
 * Whether ordering is enabled is enabled
 * @type {string}
 */
export const ordering = "ordering" + attrib_suffix;

/**
 * Whether daily notifications are enabled
 * @type {string}
 */
export const daily = "daily" + attrib_suffix;

/**
 * Whether weekly notifications are enabled
 * @type {string}
 */
export const weekly = "weekly" + attrib_suffix;

/**
 * Whether monthly notifications are enabled
 * @type {string}
 */
export const monthly = "monthly" + attrib_suffix;

/**
 * Whether user is enabled
 * @type {string}
 */
export const enabled = "enabled" + attrib_suffix;